import { TAuthContext } from "@timwoods/bf-auth";
import { getConfigValue } from "@timwoods/bf-config";

export interface INewTokenDTO {}
export interface IToken {
  id: string;
  revoked: boolean;
}
export interface IFullToken extends IToken {
  apiKey: string;
}

const makeHeader = async (auth: TAuthContext) => {
  const bearer = await auth.getToken();
  return {
    Authorization: `Bearer ${bearer}`,
  };
};

const getTokens = async (auth: TAuthContext): Promise<any[]> => {
  if (!auth.isAuthenticated) {
    return [];
  }
  const endpoint = getConfigValue("REACT_APP_TOKENS_ENDPOINT");
  const headers = await makeHeader(auth);
  console.log({ endpoint });
  const tokens = (
    await fetch(`${endpoint}/tokens`, {
      headers,
    })
  ).json();
  return tokens;
};

const createToken = async (
  token: INewTokenDTO,
  auth: TAuthContext
): Promise<IFullToken | false> => {
  if (!auth.isAuthenticated) {
    return false;
  }
  const endpoint = getConfigValue("REACT_APP_TOKENS_ENDPOINT");
  console.log({ endpoint });
  const headers = await makeHeader(auth);

  const tokens = (
    await fetch(`${endpoint}/token`, {
      method: "POST",
      body: JSON.stringify(token),
      headers,
    })
  ).json();
  return tokens;
};
export const api = (auth: TAuthContext) => {
  return {
    getTokens: () => getTokens(auth),
    createToken: (token: INewTokenDTO) => createToken(token, auth),
  };
};
