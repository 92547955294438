import "./App.css";

import KeycloakProvider from "@timwoods/bf-auth/lib/Providers/Keycloak/Keycloak";
import React from "react";
import { Tokens } from "./pages/tokens";

function App() {
  return (
    <KeycloakProvider>
      <div className="App">
        <Tokens></Tokens>
      </div>
    </KeycloakProvider>
  );
}

export default App;
