import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { IFullToken, IToken, api } from "../utils/api";
import React, { Fragment, FunctionComponent, useEffect, useState } from "react";

import Alert from "@material-ui/lab/Alert";
import AssignmentIcon from "@material-ui/icons/Assignment";
import KeyIcon from "@material-ui/icons/VpnKey";
import copy from "copy-to-clipboard";
import { useAuth } from "@timwoods/bf-auth";

const useStyles = makeStyles({
  tokenCard: { margin: 4, width: 450 },
  keyIcon: { margin: 4 },
});

const SimpleDialog: FunctionComponent<{
  open: boolean;
  title: string;
  onClose: () => void;
}> = ({ open, children, onClose, title }) => {
  return (
    <Dialog
      maxWidth={false}
      onClose={() => {
        onClose();
      }}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      {children}
    </Dialog>
  );
};

export const Tokens: FunctionComponent = () => {
  const auth = useAuth();
  const [tokens, setTokens] = useState<IToken[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [newToken, setNewToken] = useState<IFullToken | false>(false);
  const refreshTokens = async () => {
    const { getTokens } = api(auth);
    const t = await getTokens();
    setTokens(t);
  };
  useEffect(() => {
    refreshTokens();
  }, [auth.isAuthenticated]);

  const requestNewToken = async () => {
    const { createToken } = api(auth);
    const aNewToken = await createToken({});
    setNewToken(aNewToken);
    await refreshTokens();
  };
  useEffect(() => {
    if (newToken) {
      setDialogOpen(true);
    }
  }, [newToken]);
  const { tokenCard, keyIcon } = useStyles();

  return (
    <>
      <SimpleDialog
        open={dialogOpen}
        title="New Token"
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        {" "}
        <div style={{ width: "80vw", padding: 4 }}>
          <Alert severity="info">
            Once you close this dialog you will not be able to retreave this
            token again, Please store in a safe place
          </Alert>
          <Alert severity="warning" style={{ marginTop: 8 }}>
            This token allows an application to do most things in timwoods as
            you, Please keep it safe
          </Alert>
          {newToken && (
            <>
              <TextField
                style={{ marginTop: 8 }}
                fullWidth
                variant="outlined"
                label={`api key - ${newToken.id}`}
                value={newToken.apiKey}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Copy to Clipboard"
                        onClick={() => {
                          copy(newToken.apiKey);
                        }}
                      >
                        <AssignmentIcon></AssignmentIcon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              <TextField
                fullWidth
                variant="outlined"
                label="header"
                style={{ marginTop: 8 }}
                value="x-external-authentication"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Copy to Clipboard"
                        onClick={() => {
                          copy("x-external-authentication");
                        }}
                      >
                        <AssignmentIcon></AssignmentIcon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </>
          )}
        </div>
      </SimpleDialog>

      <Button
        style={{ marginTop: 10 }}
        color="primary"
        onClick={() => requestNewToken()}
        variant="contained"
      >
        Create Token
      </Button>
      <Box alignItems="center" display="flex" flexWrap="wrap">
        {tokens.map((token) => {
          return (
            <Fragment key={token.id}>
              <Card className={tokenCard}>
                <CardContent style={{ textAlign: "left" }}>
                  <KeyIcon
                    className={keyIcon}
                    color={token.revoked ? "disabled" : "primary"}
                  ></KeyIcon>
                  {token.id}
                </CardContent>
              </Card>
            </Fragment>
          );
        })}
      </Box>
    </>
  );
};
